import { render, staticRenderFns } from "./mynews.vue?vue&type=template&id=3b09bc4c"
import script from "./mynews.vue?vue&type=script&lang=js"
export * from "./mynews.vue?vue&type=script&lang=js"
import style0 from "./mynews.vue?vue&type=style&index=0&id=3b09bc4c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "mynews.vue"
export default component.exports