
<template>
  <div class="newslist bgblue mr10">
    <div class="form">
      <el-form label-width="100px" class="demo-form-inline bgwhite pt10">
        <el-row>
          <el-col :span="4">
            <el-form-item label="标题:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="消息类型:">
              <el-select
                v-model="formInline.region"
                placeholder="请选择消息类型"
                style="width: 100%"
              >
                <el-option
                  v-for="item in roofType"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="状态:">
              <el-select
                v-model="formInline.region"
                placeholder="请选择状态"
                style="width: 100%"
              >
                <el-option
                  v-for="item in roofType"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间:">
              <el-date-picker
                style="width: 100%"
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" style="margin-left: 20px">
              <i class="el-icon-search"></i>查询</el-button
            >
            <el-button plain> <i class="el-icon-refresh"></i>重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="data">
      <div class="pad10 bgwhite mt10">
        <el-table :data="tableData" border height="calc(100vh - 260px)">
        <el-table-column label="操作" width="100" align="center">
          <el-button type="text" class="btn-blue">查看</el-button>
        </el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="content"
          label="内容摘要"
          width="270"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="type" label="消息类型" align="center">
        </el-table-column>
        <el-table-column prop="ind" label="状态" align="center" width="100px">
          <el-tag type="warning">未读</el-tag>
        </el-table-column>
        <el-table-column prop="time" label="创建时间" align="center">
        </el-table-column>
        
      </el-table>
      <div class="pagination textr">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},

  data() {
    return {
      tableData: [
        {
          title: "申请客户转移",
          type: "其他",
          content: "客户需要转移到另外一个平台",
          ind: "未读",
          time: "2010-09-09 18:00:00",
        },
        {
          title: "申请客户转移",
          type: "其他",
          content: "客户需要转移到另外一个平台",
          ind: "未读",
          time: "2010-09-09 18:00:00",
        },
      ],
      formInline: {},
    };
  },
};
</script>

<style lang="less">
.newslist {
  padding: 10px;
  .el-form-item__label{
    font-size: 13px;
  }
  .el-table{
    margin-top: 10px;
  }
  .el-table--border th{
    padding:2px 0
  }
  .el-form .el-row .el-col-4 {
    width: 19.5%;
    .form {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
    }
    .data {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
    }
  }
}
</style>